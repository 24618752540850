import { useState, useCallback } from 'react';
import saveAs from 'file-saver';
import { errorActions } from '../actions';
import { useAppDispatch } from './useAppDispatch';
import { DownloadFile } from '../types/amr-pipeline/models/DownloadedDocument';

interface Props<T> {
    documents: T[];
    downloadRequest: (referenceName: string, ...rest: any[]) => Promise<DownloadFile>;
    dealReferenceName?: string;
    transactionReferenceName?: string;
    uniqueIdentifier?: keyof T;
}

export function useDownloadDocumentList<T>({ documents = [], downloadRequest, uniqueIdentifier = 'referenceName' as keyof T }: Props<T>) {
    const dispatch = useAppDispatch();

    const [loadingState, setLoadingState] = useState<Record<string, boolean>>(
        documents.reduce((acc, doc) => {
            const docKey = doc[uniqueIdentifier];

            if (docKey) {
                acc[docKey as string] = false;
            }

            return acc;
        }, {} as Record<string, boolean>),
    );

    const loadHandler = useCallback(
        async (referenceName: string, documentName?: string, ...rest: any[]) => {
            setLoadingState(prevState => ({ ...prevState, [referenceName]: true }));

            try {
                setLoadingState(prevState => ({
                    ...prevState,
                    [referenceName]: true,
                }));

                const file = await downloadRequest(referenceName, ...rest);

                saveAs(file.blob, documentName ?? file.name);
            } catch (e) {
                dispatch(errorActions.error(e));
            } finally {
                setLoadingState(prevState => ({
                    ...prevState,
                    [referenceName]: false,
                }));
            }
        },
        [downloadRequest, dispatch],
    );

    return {
        loadingState,
        loadHandler,
    };
};

export default useDownloadDocumentList;
